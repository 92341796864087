/* body {
  background: transparent !important;
} */
.diseaseWebview {
  padding: 20px 10px;
}

.diseaseWebview .diseaseRangeSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20;
  justify-content: space-evenly;
}

.diseaseWebview #selectDisease {
  width: 50%;
  height: 36px;
  border: none;
  padding: 0 10px;
  color: #7e8299;
  font-weight: bold;
  background: #f5f8fa;
}
