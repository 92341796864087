.auth {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginate_button {
  cursor: pointer;
}

.header-container {
  display: flex;
}

.react-tabs__tab {
  opacity: 0.3;
  transition: 0.4s ease;
  outline: none;
  list-style: none;
  padding: 15px 10px;
  background: #f1f1f1;
  cursor: pointer;
}

.active {
  opacity: 1;
}

.react-tabs__tab-panel {
  transition: 0.4s ease;
  opacity: 0;
}

.react-tabs__tab-panel--selected {
  opacity: 1;
}

.menu .menu-link {
  color: #fff;
  transition: 0.3s ease;
}
.menu .menu-link.active {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.page-loading::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  opacity: 0.2;
  background-color: #000;
}

.page-loading:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 7px;
  background-color: var(--kt-success);
  z-index: 10000;
  animation: loader 1s infinite;
  animation-timing-function: linear;
}

@keyframes loader {
  0% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}

.temp {
  position: relative;
  display: flex;
  width: 170px;
  height: 170px;
  margin: 0 auto;
  border-radius: 100%;
  border: 4px solid var(--yellow);
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.temp h1 {
  color: var(--yellow);
}

.temp h6 {
  position: absolute;
  top: 30px;
}

.loader .text {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader:before {
  content: "";
  position: absolute;
  top: calc(50% - 40px);
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #000, 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.7), 1.8em -1.8em 0 0em #000,
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.5),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7), 2.5em 0em 0 0em #000,
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5), 2.5em 0em 0 0em rgba(0, 0, 0, 0.7),
      1.75em 1.75em 0 0em #000, 0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.5),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.7), 0em 2.5em 0 0em #000,
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.5), 0em 2.5em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em 1.8em 0 0em #000, -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.7), -2.6em 0em 0 0em #000,
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.5), -2.6em 0em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em -1.8em 0 0em #000;
  }
}

.breadcrumb-dot .breadcrumb-item:after {
  margin: 0 5px;
}

.screen-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #fff;
  background-color: var(--kt-app-bg-color);
}

.historical-tabs .historical-tab {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px 0;
  outline: none;
}

.historical-tabs .historical-tab span {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #1f1e2e;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
}

.historical-tabs .historical-tab:hover span,
.historical-tabs .historical-tab.active span {
  background-color: #eb4163;
}

.historical-tabs .historical-tab.active div {
  font-weight: 600;
}

.historical-tabs .historical-tab span i {
  color: #fff;
  opacity: 1;
}

.custom-tooltip {
  position: relative;
}

.custom-tooltip:after {
  font-size: 12px;
  content: attr(data);
  font-weight: 400;
  font-family: Inter, Helvetica, "sans-serif";
  position: absolute;
  top: -40px;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  color: #435177;
  max-width: 200px;
  width: 200px;
  padding: 0.75rem 1rem;
  text-align: center;
  border-radius: 0.475rem;
  transition: 0.3s ease;
}

.custom-tooltip:hover:after {
  top: -50px;
  opacity: 1;
}

.wind-widget {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.wind-widget .arrow {
  position: absolute;
  z-index: 2;
}
.wind-widget .wind-value {
  width: 80px;
  height: 80px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  background-color: #eee;
}

.nav-line-tabs.nav-line-tabs-2x .nav-item {
  cursor: pointer;
}

.modal-overlay {
  z-index: 999;
}
.react-tabs__tab--selected {
  opacity: 1;
}
